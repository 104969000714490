<template>
	<div class="card">
		<div class="card-body">
			<h4 class="header-title mb-3">Сброс пароля</h4>

			<div class="form-group">
				<label for="simpleinput">Старый пароль <i class="required-asterisk">*</i></label>
				<input type="password" class="form-control" v-model="form.old_password" />
			</div>

			<div class="form-group">
				<label for="simpleinput">Новый пароль <i class="required-asterisk">*</i></label>
				<input type="password" class="form-control" v-model="form.new_password" />
			</div>

			<button v-if="!loading" class="btn btn-primary" @click="resetPassword()">
				Сбросить
			</button>
			<button v-else class="btn btn-primary" type="button" disabled>
				<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
				Загрузка...
			</button>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import log from '@/plugins/log';
import { to, sleep } from '@/plugins/utils';

export default {
	name: 'SettingsSecurity',
	data: () => {
		return {
			loading: false,
			resetF: {
				old_password: '',
				new_password: '',
            },
            form: {}
		};
	},
	async beforeMount() {
		this.resetForm();
	},
	computed: {
		...mapState({
			user: state => state.auth.user,
		}),
	},
	methods: {
		resetForm() {
			this.form = Object.assign({}, this.resetF);
		},
		async resetPassword() {
			if (this.loading) return $.NotificationApp.send('Error', 'Please wait', 'top-right', '', 'error');

			if (!this.form.old_password) return $.NotificationApp.send('Error', 'Пожалуйста, введите текущий пароль', 'top-right', '', 'error');
			if (!this.form.new_password) return $.NotificationApp.send('Error', 'Пожалуйста, введите новый пароль', 'top-right', '', 'error');

			this.loading = true;

			var [err, response] = await to(this.$http.post('/auth/change_password', this.form));
			if (err) {
				this.loading = false;
				log.error('methods.submitForm', '$http.request', err);
				if (err.response && err.response.data && err.response.data.error) {
					if (err.response.data.error == 'failed_validation') {
						return $.NotificationApp.send('Ошибка', 'Проверьте правильность заполнения полей', 'top-right', '', 'error');
					}
				}

				return $.NotificationApp.send('Error', err.message, 'top-right', '', 'error');
			}
			if (!response.data.success) {
				this.loading = false;
				log.error('methods.submitForm', '$http.response', response.data);
				return $.NotificationApp.send('Ошибка', response.data.error, 'top-right', '', 'error');
            }
            
            await sleep(150)
            this.loading = false;
			this.resetForm()
		},
	},
};
</script>
